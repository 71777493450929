<template>
	<div class="form">
		<div class="form__head">
			<h1 class="form__title text-5xl">{{ formTitle }}</h1>

			<p class="form__desc text-2xl">{{ formDesc }}</p>
		</div>

		<div class="form__main">
			<Message
				v-if="messageData"
				class="form__message"
				severity="error"
				:closable="false">
				{{ messageData }}
			</Message>

			<PageLoginIndexFormLogin
				v-if="loginFormName === 'login'"
				class="form__form"
				@set-message="setMessage($event)"
				@login-by="loginShowTypeForm($event)" />

			<PageLoginIndexFormPhone
				v-if="loginFormName === 'phoneNumber'"
				class="form__form"
				@login-by="loginShowTypeForm($event)" />

			<PageLoginIndexFormCode
				v-if="loginFormName === 'code'"
				class="form__form" />
		</div>

		<div class="form__footer">
			<router-link to="/login/password-recovery">
				<Button
					class="text-xl"
					label="Забыли пароль?"
					link />
			</router-link>
		</div>
	</div>
</template>

<script setup>
	const formTitle = ref("Вход в личный кабинет");
	const formDesc = ref("Введите свои данные, чтобы начать пользоваться личным кабинетом");

	const messageData = ref(null);
	const setMessage = (e) => {
		messageData.value = e;
	};

	const loginFormName = ref("login");
	const loginShowTypeForm = (obj) => {
		loginFormName.value = obj.name;
		messageData.value = null;

		if (obj.name === "code") {
			formTitle.value = "Код подтверждения";
			formDesc.value = "На указанный номер был отправлен код доступа. Чтобы войти в личный кабинет, введите 6-значный код активации";
		}
	};
</script>

<style scoped lang="scss">
	@import "form";
</style>
