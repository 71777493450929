<template>
	<form class="form-code">
		<div class="form-code__wrapper">
			<InputOtp
				id="code"
				v-model="dataLogin.code"
				:length="6"
				variant="outlined"
				integer-only />

			<Timer
				class="form-code__timer"
				text-action="Отправить код повторно через" />
		</div>

		<Button
			class="form__button-action"
			label="Войти"
			@click="logIn()" />
	</form>
</template>

<script setup lang="ts">
	import InputOtp from "primevue/inputotp";

	const dataLogin = reactive({
		code: "",
	});

	const logIn = () => {
		setTimeout(() => {}, 1500);
	};
</script>

<style scoped lang="scss">
	@import "form-code";
</style>
