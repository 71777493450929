<template>
	<form class="form-pass">
		<form-group
			:label-text="'Номер телефона'"
			:label-for="'phone-number'"
			:class-label="'text-xl'">
			<InputMask
				id="phone-number"
				v-model="dataLogin.phoneNumber"
				mask="+7 (999) 999-9999"
				placeholder="+7 (999) 999-9999" />
		</form-group>

		<Button
			class="form__type-log-in text-xl"
			label="Войти по логину"
			link
			@click="loginBy('login')" />

		<Button
			class="form__button-action"
			label="Выслать пароль"
			@click="logIn('code')" />
	</form>
</template>

<script setup>
	const emits = defineEmits(["loginBy"]);

	const dataLogin = reactive({
		phoneNumber: "",
	});

	const loginBy = (name) => {
		emits("loginBy", { name });
	};

	const logIn = (name) => {
		setTimeout(() => {
			emits("loginBy", { name });
		}, 1500);
	};
</script>

<style scoped lang="scss"></style>
