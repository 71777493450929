<template>
	<form
		class="form-login"
		@submit="logIn">
		<form-group
			:label-text="'Логин'"
			:label-for="'login'"
			:class-label="'text-xl'">
			<InputText
				id="login"
				v-model="login"
				v-bind="loginAttrs"
				:placeholder="'Введите ваш логин или e-mail'" />
		</form-group>

		<form-group
			:label-text="'Пароль'"
			:label-for="'pass'"
			:class-label="'text-xl'">
			<Password
				v-model="pass"
				v-bind="passAttrs"
				input-id="pass"
				placeholder="Введите пароль"
				:feedback="false"
				toggle-mask />
		</form-group>
		<!--todo(sterob) кнопка скрыта до реализации функционала-->
		<!--		<Button-->
		<!--			class="form__type-log-in text-xl"-->
		<!--			label="Войти по номеру телефона"-->
		<!--			link-->
		<!--			@click="loginBy('phoneNumber')" />-->

		<Button
			class="form__button-action text-xl"
			label="Войти"
			:disabled="checkButtonDisabled"
			type="submit" />
	</form>
</template>

<script setup lang="ts">
	import { useForm } from "vee-validate";
	import { object, string } from "yup";

	const { signIn } = useAuth();

	const emits = defineEmits(["setMessage", "loginBy"]);

	const setMessage = (message: string) => emits("setMessage", message);
	// const loginBy = (name) => emits("loginBy", { name });

	const schema = object({
		login: string().required(),
		pass: string().required(),
	});

	const { handleSubmit, defineField, meta, isSubmitting } = useForm({
		validationSchema: schema,
		initialValues: {
			login: "",
			pass: "",
		},
	});

	const checkButtonDisabled = computed(() => isSubmitting.value || !(meta.value.valid && meta.value.dirty));

	const [login, loginAttrs] = defineField("login");
	const [pass, passAttrs] = defineField("pass");

	const logIn = handleSubmit(async (values) => {
		setMessage("");

		return await signIn("credentials", {
			redirect: false,
			username: values.login,
			password: values.pass,
		})
			.then(async (auth) => {
				if (auth?.error) {
					setMessage(auth.error);
				} else {
					await useRouter().push("/");
				}
			})
			.catch((error) => setMessage(error));
	});
</script>
